import FormInput from "components/form-input";
import { Formik } from "formik";
import { Button, Divider } from "@mantine/core";
import { FiCheck, FiKey, FiMail, FiX } from "react-icons/fi";
import { Link } from "react-router-dom";
import api from "utils/api";
import { showNotification } from "@mantine/notifications";
import { useStore } from "lib/store";

interface FormValues {
  email: string;
  password: string;
}

const LoginForm: React.FC = () => {
  const { setJwt, setIsAuthenticated } = useStore();

  const onValidate = (values: FormValues) => {
    const errors: Record<string, string | number> = {};
    if (!values.email) {
      errors.email = "Eingabe ist erforderlich";
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
      errors.email = "Ungültige Email Adresse";
    }
    if (!values.password) errors.password = "Eingabe ist erforderlich";

    return errors;
  };

  const onSubmit = async (values: FormValues) => {
    try {
      const { data } = await api.post("company/signin", values);
      showNotification({
        color: "green",
        icon: <FiCheck />,
        message: "Anmeldung erfolgreich",
      });
      setIsAuthenticated(true);
      setJwt(data.token);
      localStorage.setItem("token", data.token);
    } catch (err: any) {
      showNotification({
        title: "Fehler bei der Anmeldung",
        color: "red",
        icon: <FiX />,
        message: err.response.data.error,
      });
    }
  };

  return (
    <div
      style={{ maxWidth: "24rem" }}
      className="container bg-white mt-32 md:mx-auto m-4 shadow rounded-lg p-4"
    >
      <Formik<FormValues>
        initialValues={{
          email: "",
          password: "",
        }}
        validate={onValidate}
        onSubmit={onSubmit}
      >
        {({
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
        }) => (
          <form onSubmit={handleSubmit}>
            <FormInput
              placeholder="example@email.com"
              name="email"
              type="email"
              onChange={handleChange}
              onBlur={handleBlur}
              invalid={errors.email && touched.email}
              required
              label="Email"
              err={touched.email && errors.email}
              icon={<FiMail />}
            />
            <Divider my="sm" variant="dashed" />
            <FormInput
              placeholder="********"
              name="password"
              onChange={handleChange}
              onBlur={handleBlur}
              invalid={errors.password && touched.password}
              required
              label="Passwort"
              type="password"
              err={touched.password && errors.password}
              icon={<FiKey />}
            />

            <Divider my="sm" variant="dashed" />
            <Button
              fullWidth
              variant="filled"
              disabled={isSubmitting}
              type="submit"
              className="mt-2 bg-red-400 hover:bg-primary"
            >
              Anmelden
            </Button>
            <Divider my="sm" variant="dotted" />
            <p className="text-center text-sm text-gray-800">
              Sie haben noch kein Unternehmenskonto? Registrieren Sie sich{" "}
              <Link to="/signup" className="text-red-400 font-semibold">
                hier
              </Link>
            </p>
          </form>
        )}
      </Formik>
    </div>
  );
};

export default LoginForm;
