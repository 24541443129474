const Imprint: React.FC = () => {
  return (
    <div className="relative">
      <div className="max-w-7xl mx-auto px-8 py-8">
        <h1 className="text-gray-800 font-heading text-4xl my-10">Impressum</h1>
        <h2 className="text-gray-700 text-2xl mb-4">Angaben gemäß § 5 TMG:</h2>
        <div>
          <h2 className="font-heading text-gray-700 text-2xl">Jobz GmbH</h2>
          <p className="text-sm text-gray-700 mt-1">Diedenhofer Str. 8</p>
          <p className="text-sm text-gray-700 mt-1">10405 Berlin</p>
          <p className="text-sm text-gray-700 mt-1">
            Geschäftsführer: Rayan Tegtmeier
          </p>
          <h2 className="font-heading text-gray-700 text-2xl mt-4">
            Verantwortlich für den Inhalt nach § 55 Abs. 2 RStV:
          </h2>
          <p className="text-sm text-gray-700 mt-1">Rayan Tegtmeier</p>
          <h2 className="font-heading text-gray-700 text-2xl mt-4">Kontakt:</h2>
          <p className="text-sm text-gray-700 mt-1">
            Mail: info (at) jobz.group
          </p>
          <p className="text-sm text-gray-700 mt-1">Tel.: 030/629300520</p>
          <h2 className="font-heading text-gray-700 text-2xl mt-4">
            Registereintrag:
          </h2>
          <p className="text-sm text-gray-700 mt-1">Berlin HRB 220541B</p>
          <h2 className="font-heading text-gray-700 text-2xl mt-4">
            Umsatzsteuer-ID:
          </h2>
          <p className="text-sm text-gray-700 mt-1">DE314046203</p>
          <h2 className="font-heading text-gray-700 text-2xl mt-4">
            Aufsichtsbehörde:
          </h2>
          <p className="text-sm text-gray-700 mt-1">
            Bitte tragen Sie hier Ihre zuständige Aufsichtsbehörde ein
          </p>
          <h2 className="font-heading text-gray-700 text-2xl mt-4">
            Haftungsausschluss:
          </h2>
          <p className="text-sm text-gray-700 mt-1">
            <p>Haftung für Inhalte</p>
            <p>
              Die Inhalte unserer Seiten wurden mit größter Sorgfalt erstellt.
              Für die Richtigkeit, Vollständigkeit und Aktualität der Inhalte
              können wir jedoch keine Gewähr übernehmen. Als Diensteanbieter
              sind wir gemäß § 7 Abs.1 TMG für eigene Inhalte auf diesen Seiten
              nach den allgemeinen Gesetzen verantwortlich. Nach §§ 8 bis 10 TMG
              sind wir als Diensteanbieter jedoch nicht verpflichtet,
              übermittelte oder gespeicherte fremde Informationen zu überwachen
              oder nach Umständen zu forschen, die auf eine rechtswidrige
              Tätigkeit hinweisen. Verpflichtungen zur Entfernung oder Sperrung
              der Nutzung von Informationen nach den allgemeinen Gesetzen
              bleiben hiervon unberührt. Eine diesbezügliche Haftung ist jedoch
              erst ab dem Zeitpunkt der Kenntnis einer konkreten
              Rechtsverletzung möglich. Bei Bekanntwerden von entsprechenden
              Rechtsverletzungen werden wir diese Inhalte umgehend entfernen.
            </p>
            <p className="mt-2">Haftung für Links</p>
            <p>
              Unser Angebot enthält Links zu externen Webseiten Dritter, auf
              deren Inhalte wir keinen Einfluss haben. Deshalb können wir für
              diese fremden Inhalte auch keine Gewähr übernehmen. Für die
              Inhalte der verlinkten Seiten ist stets der jeweilige Anbieter
              oder Betreiber der Seiten verantwortlich. Die verlinkten Seiten
              wurden zum Zeitpunkt der Verlinkung auf mögliche Rechtsverstöße
              überprüft. Rechtswidrige Inhalte waren zum Zeitpunkt der
              Verlinkung nicht erkennbar. Eine permanente inhaltliche Kontrolle
              der verlinkten Seiten ist jedoch ohne konkrete Anhaltspunkte einer
              Rechtsverletzung nicht zumutbar. Bei Bekanntwerden von
              Rechtsverletzungen werden wir derartige Links umgehend entfernen.
            </p>
            <p className="mt-2">Urheberrecht</p>
            <p>
              Die durch die Seitenbetreiber erstellten Inhalte und Werke auf
              diesen Seiten unterliegen dem deutschen Urheberrecht. Die
              Vervielfältigung, Bearbeitung, Verbreitung und jede Art der
              Verwertung außerhalb der Grenzen des Urheberrechtes bedürfen der
              schriftlichen Zustimmung des jeweiligen Autors bzw. Erstellers.
              Downloads und Kopien dieser Seite sind nur für den privaten, nicht
              kommerziellen Gebrauch gestattet. Soweit die Inhalte auf dieser
              Seite nicht vom Betreiber erstellt wurden, werden die
              Urheberrechte Dritter beachtet. Insbesondere werden Inhalte
              Dritter als solche gekennzeichnet. Sollten Sie trotzdem auf eine
              Urheberrechtsverletzung aufmerksam werden, bitten wir um einen
              entsprechenden Hinweis. Bei Bekanntwerden von Rechtsverletzungen
              werden wir derartige Inhalte umgehend entfernen.
            </p>
            <p className="mt-2">Datenschutz</p>
            <p>
              Die Nutzung unserer Webseite ist in der Regel ohne Angabe
              personenbezogener Daten möglich. Soweit auf unseren Seiten
              personenbezogene Daten (beispielsweise Name, Anschrift oder
              eMail-Adressen) erhoben werden, erfolgt dies, soweit möglich,
              stets auf freiwilliger Basis. Diese Daten werden ohne Ihre
              ausdrückliche Zustimmung nicht an Dritte weitergegeben.
            </p>
            <p className="mt-2">
              Wir weisen darauf hin, dass die Datenübertragung im Internet (z.B.
              bei der Kommunikation per E-Mail) Sicherheitslücken aufweisen
              kann. Ein lückenloser Schutz der Daten vor dem Zugriff durch
              Dritte ist nicht möglich.
            </p>
            <p className="mt-2">
              Der Nutzung von im Rahmen der Impressumspflicht veröffentlichten
              Kontaktdaten durch Dritte zur Übersendung von nicht ausdrücklich
              angeforderter Werbung und Informationsmaterialien wird hiermit
              ausdrücklich widersprochen. Die Betreiber der Seiten behalten sich
              ausdrücklich rechtliche Schritte im Falle der unverlangten
              Zusendung von Werbeinformationen, etwa durch Spam-Mails, vor.
            </p>
            <p className="mt-2">
              Datenschutzerklärung für die Nutzung von Facebook-Plugins
              (Like-Button)
            </p>
            <p>
              Auf unseren Seiten sind Plugins des sozialen Netzwerks Facebook,
              1601 South California Avenue, Palo Alto, CA 94304, USA integriert.
              Die Facebook-Plugins erkennen Sie an dem Facebook-Logo oder dem
              'Like-Button' ('Gefällt mir') auf unserer Seite. Eine Übersicht
              über die Facebook-Plugins finden Sie hier:
              http://developers.facebook.com/docs/plugins/. Wenn Sie unsere
              Seiten besuchen, wird über das Plugin eine direkte Verbindung
              zwischen Ihrem Browser und dem Facebook-Server hergestellt.
              Facebook erhält dadurch die Information, dass Sie mit Ihrer
              IP-Adresse unsere Seite besucht haben. Wenn Sie den Facebook
              'Like-Button' anklicken während Sie in Ihrem Facebook-Account
              eingeloggt sind, können Sie die Inhalte unserer Seiten auf Ihrem
              Facebook-Profil verlinken. Dadurch kann Facebook den Besuch
              unserer Seiten Ihrem Benutzerkonto zuordnen. Wir weisen darauf
              hin, dass wir als Anbieter der Seiten keine Kenntnis vom Inhalt
              der übermittelten Daten sowie deren Nutzung durch Facebook
              erhalten. Weitere Informationen hierzu finden Sie in der
              Datenschutzerklärung von facebook unter
              http://de-de.facebook.com/policy.php
            </p>
            <p className="mt-2">
              Wenn Sie nicht wünschen, dass Facebook den Besuch unserer Seiten
              Ihrem Facebook-Nutzerkonto zuordnen kann, loggen Sie sich bitte
              aus Ihrem Facebook-Benutzerkonto aus.
            </p>
            <p className="mt-2">
              Datenschutzerklärung für die Nutzung von Google Analytics
            </p>
            <p>
              Diese Website benutzt Google Analytics, einen Webanalysedienst der
              Google Inc. ('Google'). Google Analytics verwendet sog. 'Cookies',
              Textdateien, die auf Ihrem Computer gespeichert werden und die
              eine Analyse der Benutzung der Website durch Sie ermöglichen. Die
              durch den Cookie erzeugten Informationen über Ihre Benutzung
              dieser Website werden in der Regel an einen Server von Google in
              den USA übertragen und dort gespeichert. Im Falle der Aktivierung
              der IP-Anonymisierung auf dieser Webseite wird Ihre IP-Adresse von
              Google jedoch innerhalb von Mitgliedstaaten der Europäischen Union
              oder in anderen Vertragsstaaten des Abkommens über den
              Europäischen Wirtschaftsraum zuvor gekürzt.
            </p>
            <p className="mt-2">
              Nur in Ausnahmefällen wird die volle IP-Adresse an einen Server
              von Google in den USA übertragen und dort gekürzt. Im Auftrag des
              Betreibers dieser Website wird Google diese Informationen
              benutzen, um Ihre Nutzung der Website auszuwerten, um Reports über
              die Websiteaktivitäten zusammenzustellen und um weitere mit der
              Websitenutzung und der Internetnutzung verbundene Dienstleistungen
              gegenüber dem Websitebetreiber zu erbringen. Die im Rahmen von
              Google Analytics von Ihrem Browser übermittelte IP-Adresse wird
              nicht mit anderen Daten von Google zusammengeführt.
            </p>
            <p className="mt-2">
              Sie können die Speicherung der Cookies durch eine entsprechende
              Einstellung Ihrer Browser-Software verhindern; wir weisen Sie
              jedoch darauf hin, dass Sie in diesem Fall gegebenenfalls nicht
              sämtliche Funktionen dieser Website vollumfänglich werden nutzen
              können. Sie können darüber hinaus die Erfassung der durch das
              Cookie erzeugten und auf Ihre Nutzung der Website bezogenen Daten
              (inkl. Ihrer IP-Adresse) an Google sowie die Verarbeitung dieser
              Daten durch Google verhindern, indem Sie das unter dem folgenden
              Link verfügbare Browser-Plugin herunterladen und installieren:
              http://tools.google.com/dlpage/gaoptout?hl=de.
            </p>
            <p className="mt-2">
              Quellverweis: Disclaimer eRecht24, Facebook Disclaimer von
              eRecht24, Datenschutzerklärung Google Analytic
            </p>
            <p>
              Quelle: Erstellt mit dem Impressum-Generator der Kanzlei Siebert
            </p>
          </p>
        </div>
      </div>
    </div>
  );
};

export default Imprint;
