import axios from "axios";

const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": "*",
    "client-id": process.env.REACT_APP_CLIENT_ID || "",
  },
});

api.interceptors.request.use((config: any) => {
  const token = localStorage.getItem("token");

  if (token)
    config.headers.Authorization = "Bearer " + localStorage.getItem("token");

  return config;
});

export default api;
