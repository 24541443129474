import classNames from "classnames";

const Title: React.FC = ({ children }) => {
  return <h2 className="font-sans font-semibold text-lg mt-4">{children}</h2>;
};

const Link: React.FC<{ link: string; linkText?: string }> = ({
  link,
  linkText,
}) => {
  return (
    <a
      className="text-red-500 font-semibold"
      href={link}
      target="_blank"
      rel="noreferrer"
    >
      {linkText ? linkText : link}
    </a>
  );
};

const Paragraph: React.FC<{ hasM?: boolean }> = ({ children, hasM }) => {
  return <p className={classNames({ "mt-4": hasM })}>{children}</p>;
};

const TermsAndConditions: React.FC = () => {
  return (
    <div className="relative overflow-hidden">
      <div className="max-w-7xl mx-auto px-8 py-8">
        <h1 className="text-gray-800 font-heading text-4xl mt-10">
          Allgemeine Geschäftsbedingungen zur Vermittlung von Bewerbern über{" "}
          {process.env.REACT_APP_FRONTEND_URL?.replace("https://", "")}
        </h1>
        <p className="mt-4">Allgemeine Geschäftsbedingungen für Bewerber</p>
        <p>gültig ab 01.01.2022</p>
        <p className="mt-4">
          Diese Allgemeinen Geschäftsbedingungen sind die verbindlichen Regeln,
          die zwischen der Jobz.Group - Jobz GmbH , Diedenhofer Str. 8 - 10405
          Berlin - im Folgenden 'Jobz.Group' genannt – und Ihnen bei der Nutzung
          von Jobz.Group als Bewerber (im Folgenden 'Bewerber' oder 'Kandidat')
          gelten.
        </p>
        <div>
          <ol className="list-decimal text-gray-700 pl-5 mt-4">
            <li>
              <Title>Leistungsbeschreibung</Title>
              <Paragraph>
                1.1 Auf der Webseite https://www.jobz.Group und mit den mobilen
                Apps für iOS und Android Endgeräte stellt Jobz.Group Ihnen die
                auf Steuern und Buchhaltung spezialisierte Jobplattform
                Jobz.Group ('Plattform') kostenlos zur Verfügung. Jobz.Group
                bietet Ihnen die Möglichkeit, kostenlos ein eigenes
                Bewerberprofil mit Lebenslauf auf der Plattform einzustellen und
                mit potenziellen Arbeitgebern in Echtzeit in Kontakt zu treten
                und zu interagieren. Arbeitgeber können aus allen Bereichen der
                freien Wirtschaft, des Öffentlichen Dienstes und in allen Größen
                sowie Gesellschaftsformen vertreten sein.
              </Paragraph>
              <Paragraph hasM>
                1.2 Zur Nutzung von Jobz.Group registrieren Sie sich mit einer
                aktuellen E-Mail-Adresse und dem gewünschten Passwort. Hierin
                liegt Ihr Angebot zum Abschluss eines Vertrages mit Jobz.Group.
                Der Vertrag kommt erst mit Annahme Ihres Angebotes durch
                Jobz.Group zustande. Mit Annahme Ihres Angebotes durch
                Jobz.Group erhalten Sie von Jobz.Group eine E-Mail an die von
                Ihnen angegebene E-Mail-Adresse. Diese enthält einen Link, mit
                der Sie Ihre E-Mail-Adresse bestätigen können. Erst mit der
                Bestätigung Ihrer E-Mail-Adresse können Sie die Plattform
                vollumfänglich nutzen.
              </Paragraph>
              <Paragraph hasM>
                1.3 Im Anschluss können Sie Ihr Bewerberprofil anlegen.
                Grundsätzlich erscheint Ihr Profil für potentielle Arbeitgeber
                anonym, d.h. an Ihrem Profil interessierte Arbeitgeber sehen von
                Ihnen kein Bild, keinen Vor- und Nachnamen, lediglich die
                Leistungsdaten (Noten, Titel / Abschlüsse, bisherige
                Berufserfahrung in Jahren, etc.). Erst wenn Sie Interesse an
                einer Stelle bekunden, werden Ihr Bild, Ihr Vor- und Nachname,
                Ihre Kontaktdaten, Ihre Selbstdarstellung und Ihr Lebenslauf,
                etc. dem potentiellen Arbeitgeber zugänglich gemacht. Beim
                Ausfüllen Ihres Profils können Sie auch Ihre Präferenzen für
                eine neue berufliche Herausforderung (Wunscharbeitgeber,
                Wunschposition, Gehaltsvorstellung, Wunschstandort, etc.)
                angeben.
              </Paragraph>
              <Paragraph hasM>
                1.4 Sie sind verpflichtet, alle Angaben wahrheitsgemäß zu machen
                und etwaige Vertraulichkeitspflichten aus Gesetz und gegenüber
                Ihrem derzeitigen oder früheren Arbeitgeber zu beachten. Zur
                Pflicht, wahrheitsgemäße Angaben zu machen, gehört auch, dass
                Ihre Angaben vollständig sein müssen und einen verständigen
                Arbeitgeber nicht in die Irre führen dürfen. Sollten Sie
                schuldhaft unwahre Angaben gemacht haben, sind Sie verpflichtet,
                das von Jobz.Group ausgezahlte Startgeld (siehe 2.6, 2.7)
                zurückzahlen und gegebenenfalls weiteren Schadensersatz zu
                leisten, wenn und soweit Jobz.Group durch diese unwahren Angaben
                ein Schaden entstanden ist. Sie sind weiter verpflichtet,
                Jobz.Group von Ansprüchen Dritter, insbesondere potenzieller
                Arbeitgeber, freizustellen. Weitere Ansprüche, insbesondere
                Schadensersatzansprüche Dritter oder Schadensersatzansprüche von
                potenziellen Arbeitgebern, bleiben unberührt.
              </Paragraph>
              <Paragraph hasM>
                1.5 Jobz.Group möchte sicherstellen, dass alle angelegten
                Nutzerprofile für die potenziellen Arbeitgeber verlässlich und
                aussagekräftig sind. Jobz.Group schaltet daher die Profile erst
                nach einer manuellen Prüfung frei. Jobz.Group behält sich vor,
                ist aber nicht verpflichtet, einzelne Angaben zu überprüfen,
                indem Jobz.Group beispielsweise von Ihnen einen Nachweis über
                die Noten (z.B. Abschlusszeugnisse) oder die Vorlage eines
                Arbeitszeugnisses verlangt.
              </Paragraph>
              <Paragraph hasM>
                1.6 Nach Freischaltung Ihres Profils durch Jobz.Group wird es in
                Echtzeit mit bei Jobz.Group hinterlegten Stellen potentieller
                Arbeitgeber (“Vakanzen”) abgeglichen (“gepairt”). Passen Ihr
                Profil und eine Stelle zusammen, gibt es eine “Neue Option”.
                Jobz.Group zeigt Ihnen für Sie interessante Arbeitgeber, die mit
                Ihren Daten und Ihren ausgewählten Präferenzen übereinstimmen.
                Für Sie besteht dann die Möglichkeit, gegenüber potentiellen
                Arbeitgebern aktiv Ihr Interesse (= 'Daumen hoch') an einer
                Vakanz zu bekunden. Genauso können Arbeitgeber Interesse an
                Ihrem Bewerberprofil zum Ausdruck bringen. Wenn eine neue
                Anfrage für Sie vorliegt, benachrichtigt Jobz.Group Sie per
                E-Mail an Ihre bei Jobz.Group hinterlegte E-Mail-Adresse. Bei
                beidseitigem Interesse kommt es zu einem Match. Sie können dann
                mit dem Arbeitgeber per Nachricht direkt in Kontakt treten. Der
                Arbeitgeber kann Ihnen nach seinem Ermessen einen Telefontermin
                und einen Termin für ein persönliches Gespräch vorschlagen. Wenn
                Sie mit einem potenziellen Arbeitgeber in Kontakt treten
                möchten, so beauftragen Sie Jobz.Group damit, Ihr Interesse an
                diesen zu übermitteln.
              </Paragraph>
              <Paragraph hasM>
                1.7 Alternativ zu den in der Leistungsbeschreibung genannten
                Punkten, kann eine Positionsempfehlung sowie die Weiterleitung
                der Unterlagen an interessierte Auftraggeber auch via Profil und
                fernmündlicher oder schriftlicher Kommunikation geschehen.
                (“klassische” Personalvermittlung)
              </Paragraph>
              <Paragraph hasM>
                1.8 Ein Anspruch auf eine erfolgreiche Vermittlung besteht
                nicht.
              </Paragraph>
            </li>
            <li>
              <Title>Pflichten von Jobz.Group</Title>
              <Paragraph>
                2.1 Jobz.Group stellt Ihnen die Plattform auf
                https://Jobz.Group.de und ihre mobilen Apps entsprechend der
                oben dargestellten Leistungsbeschreibung kostenlos zur
                Verfügung.
              </Paragraph>
              <Paragraph hasM>
                2.2 Die von Jobz.Group angebotenen Dienste unterliegen einer
                kontinuierlichen Weiterentwicklung. Die Form und Beschaffenheit
                der von Jobz.Group angebotenen Dienste kann sich daher von Zeit
                zu Zeit in zumutbarem Umfang ändern. Jobz.Group behält sich
                darüber hinaus vor, die Bereitstellung der Dienste (oder
                Funktionen innerhalb der Dienste) für einzelne oder alle Nutzer
                zeitweise oder dauerhaft einzustellen. Wir behalten uns außerdem
                das Recht vor, nach eigenem Ermessen, jedoch unter angemessener
                Berücksichtigung Ihrer Interessen, Einschränkungen in Bezug auf
                die Nutzung und Speicherkapazitäten festzulegen. Diese
                Änderungen oder Einschränkungen der Dienste teilt Jobz.Group
                Ihnen mit angemessenem Vorlauf mit. Es obliegt Ihnen, die auf
                Jobz.Group eingestellten Daten anderweitig als Sicherheitskopie
                abzuspeichern. So stellen Sie sicher, auf die Daten auch dann
                Zugriff zu haben, wenn diese bei Jobz.Group aufgrund einer
                etwaigen technischen Störung oder einer Einstellung des Dienstes
                oder Teilen desselben verloren gehen.
              </Paragraph>
              <Paragraph hasM>
                2.3 Die Verfügbarkeit der Plattform beträgt 99 % im
                Jahresmittel. Ausgenommen sind Zeiten, in denen die Server wegen
                routinemäßiger und zuvor angekündigter Wartungsarbeiten oder
                Störungen außerhalb des Einflussbereiches von Jobz.Group nicht
                verfügbar sind.
              </Paragraph>
              <Paragraph hasM>
                2.4 Die Anzahl der Arbeitgeber und deren Vakanzen, die auf
                Jobz.Group verfügbar sind, kann Schwankungen unterliegen.
                Jobz.Group kann nicht gewährleisten, dass Arbeitgeber und deren
                Vakanzen für Sie bei Jobz.Group verfügbar sind.
              </Paragraph>
              <Paragraph hasM>
                2.5 Jobz.Group bietet Ihnen eine Plattform an und beteiligt sich
                nur dann auch inhaltlich an der Kommunikation zwischen Ihnen und
                dem potentiellen Arbeitgeber, sofern diese Dienstleistung von
                dem Arbeitgeber erwünscht ist.
              </Paragraph>
              <Paragraph hasM>
                2.6 Wenn Sie durch die Unterstützung von Jobz.Group einen
                Arbeitsvertrag mit einem neuen Arbeitgeber abschließen, erhalten
                Sie – soweit nicht bei einzelnen Stellenangeboten etwas anderes
                angegeben ist – von Jobz.Group 3% Ihres vertraglich
                zugesicherten ersten Bruttojahreseinkommens (= Startgeld) auf
                ein Bankkonto überwiesen, welches Sie Jobz.Group zu diesem Zweck
                innerhalb eines Jahres ab Erfüllung der Voraussetzung mitteilen
                müssen. Soweit nicht auf der Plattform bei einzelnen
                Stellenangeboten etwas anderes angegeben ist, ist das
                'Bruttojahreseinkommen‘ das vertraglich vereinbarte
                Bruttogehalt, das Ihnen vom Arbeitgeber für die ersten 12 Monate
                Ihrer Tätigkeit im Rahmen der Anstellung gezahlt werden soll.
                Zusätzlich umfasst das Bruttojahreseinkommen auch alle anderen
                vertraglich vereinbarten Zahlungen an Sie im Rahmen der
                Anstellung, die wirtschaftlich den ersten 12 Monaten der
                Tätigkeit zuzurechnen sind, wie z.B. 13. und 14.
                Monatseinkommen, Gratifikationen, Provisions- oder
                Bonuszahlungen, sowie Gewinnbeteiligungen, und andere zu Ihren
                Gunsten erfolgende Zahlungen, sofern diese steuerlich als
                Gehaltszahlungen zu Ihren Gunsten anzusehen sind.
              </Paragraph>
              <Paragraph hasM>
                2.7 Voraussetzung für die Zahlung des Startgelds ist das
                erfolgreiche Bestehen der Probezeit. 'Probezeit' ist die
                zwischen Ihnen und dem Arbeitgeber anfänglich vertraglich
                vereinbarte Probezeit, soweit diese einen Zeitraum von sechs
                Monaten ab der vorgesehenen Aufnahme der Tätigkeit nicht
                übersteigt. Der Anspruch auf das Startgeld besteht weiterhin
                nur, wenn Sie mit dem neuen Arbeitgeber nicht schon vorher auf
                andere Weise in Kontakt waren und der neue Arbeitgeber
                übereinstimmend angibt, dass er durch Jobz.Group erstmalig in
                Kontakt mit Ihnen getreten ist und erst durch Jobz.Group Ihren
                Lebenslauf erhalten hat. Wenn Sie zum Beispiel vorher bereits
                ein Praktikum, Referendariat, o.Ä. bei Ihrem neuen Arbeitgeber
                absolviert haben, besteht kein Anspruch auf das Startgeld. Das
                Startgeld wird Ihnen zudem erst ausgezahlt, wenn das
                Vermittlungshonorar durch den Arbeitgeber an Jobz.Group gezahlt
                wurde.
              </Paragraph>
              <Paragraph hasM>
                2.8 Als registrierter Jobz.Group-Nutzer können Sie Jobz.Group
                weiterempfehlen und erhalten im Falle der erfolgreichen
                Vermittlung der eingeladenen Person über Jobz.Group einen
                Vermittlungsbonus in Höhe von 500 €. Die Vermittlung gilt erst
                dann als erfolgreich, wenn die vermittelte Person die Probezeit
                erfolgreich bestanden hat und ihrerseits das Startgeld verdient
                hat. Erst dann wird der Vermittlungsbonus ausgezahlt. Der
                Vermittlungsbonus wird auch nicht gezahlt, wenn die vermittelte
                Person wesentlich gegen ihre Pflichten gegenüber Jobz.Group
                verstoßen hat. Für jede über Jobz.Group vermittelte Person wird
                der Vermittlungsbonus maximal einmal ausgezahlt. Der
                Vermittlungsbonus wird dem Nutzer ausgezahlt, der zum Zeitpunkt
                der Vermittlung der eingeladenen Person als Empfehlungsgeber in
                deren Profil angegeben ist. Ein Nutzer gilt nur als eingeladen,
                wenn dieser die E-Mail-Adresse des Empfehlungsgebers bei der
                Registrierung im Abschnitt “Woher kennen Sie Jobz.Group” unter
                dem Punkt “Empfehlung / Einladung” angibt. Wenn sich ein Nutzer
                über einen Empfehlungslink direkt registriert – das heißt ohne
                die Seite zu schließen oder neu zu laden –, geschieht dies
                automatisch. Der Abschnitt “Woher kennen Sie Jobz.Group” wird
                dann nicht angezeigt. Löschen Sie Ihren Jobz.Group-Account, so
                geben Sie Ihren Anspruch auf Vermittlungsboni auf. Der Anspruch
                auf den Vermittlungsbonus verfällt, wenn Sie sich nach
                Kontaktaufnahme von Jobz.Group zwecks Auszahlung des Bonus nicht
                innerhalb eines Zeitraumes von drei Monaten melden und eine
                aktuelle Bankverbindung angeben.
              </Paragraph>
            </li>
            <li>
              <Title>Ihre Pflichten als Nutzer der Plattform Jobz.Group</Title>
              <Paragraph>
                3.1 Sie verpflichten sich, sich nur einmal zu registrieren und
                Dritten Ihr Bewerberkonto nicht zu überlassen. Sie sind zudem
                selbst für die Geheimhaltung und Sicherheit Ihres Passworts
                verantwortlich. Sie sind ebenfalls dafür verantwortlich, dass
                Endgeräte, auf denen Sie Ihr Passwort gespeichert haben, nicht
                durch Dritte genutzt werden.
              </Paragraph>
              <Paragraph hasM>
                3.2 Sie bestätigen mit der Registrierung als Bewerber, dass Sie
                nicht personalvermittelnd tätig sind und nicht im Auftrag
                solcher Personen arbeiten. Eventuell entstehende
                Regressansprüche/Schadensersatz werden unverzüglich geltend
                gemacht und zzgl. Bußgeldern in Rechnung gestellt. Weiterhin
                behält sich Jobz.Group vor Anzeige zu erstatten.
              </Paragraph>
              <Paragraph hasM>
                3.3 Bei einem Missbrauch Ihres Bewerberkontos oder einem
                entsprechenden Verdacht oder bei Verlust Ihres Passwortes sind
                Sie verpflichtet, Jobz.Group dies unverzüglich anzuzeigen, z.B.
                per E-Mail. Dasselbe gilt für die Nutzung eines Endgerätes, auf
                dem Sie Ihr Passwort gespeichert haben, durch Dritte.
              </Paragraph>
              <Paragraph hasM>
                3.4 Ihnen ist es verboten, Angriffe auf das Angebot von
                Jobz.Group vorzunehmen, wie beispielsweise Spam- oder
                Brute-Force-Attacken, Hacking-Versuche oder der Einsatz von
                Spionage-Software, Viren und Ähnlichem. Insbesondere die
                Sammlung nicht-eigener Nutzerdaten ist verboten.
              </Paragraph>
              <Paragraph hasM>
                3.5 Sie werden gebeten, nach erfolgreicher Beendigung der
                Probezeit bei Ihrer neuen Stelle für die Überweisung des
                Startgelds Ihre persönliche Bankverbindung anzugeben, sofern die
                Voraussetzungen von 2.6 und 2.7 erfüllt sind. Für die
                Überweisung des Vermittlungsbonus ist ebenfalls die Angabe einer
                Bankverbindung notwendig. Wenn Sie keine persönliche
                Bankverbindung angeben, können wir Ihnen das Startgeld bzw. den
                Vermittlungsbonus nicht überwiesen.
              </Paragraph>
              <Paragraph hasM>
                3.6 Sie sind verpflichtet, das von Jobz.Group erhaltene
                Startgeld und den Vermittlungsbonus jeweils eigenständig zu
                versteuern. Hierfür übernimmt Jobz.Group keine Verantwortung und
                ist auch nicht steuerberatend tätig.
              </Paragraph>
            </li>

            <li>
              <Title>Nutzungsrechte an von Ihnen eingestellten Inhalten</Title>
              <Paragraph>
                Sie bleiben Inhaber sämtlicher Rechte an den von Ihnen auf der
                Plattform eingestellten Inhalten. Zum Betrieb der Plattform und
                zur Vermittlung des Kontaktes zu Arbeitgebern muss Jobz.Group,
                die von Ihnen eingestellten Inhalte vervielfältigen und ggf. im
                Rahmen der Plattform öffentlich zugänglich machen. Zu diesem
                Zweck räumen Sie mit dem Einstellen Ihrer Inhalte auf der
                Plattform Jobz.Group ein nicht ausschließliches, widerrufliches,
                auf die Dauer dieses Vertrages beschränktes und nicht
                übertragbares, jedoch zwecks Weitergabe an potenzielle
                Arbeitgeber unterlizenzierbares Nutzungsrecht an Ihren auf der
                Plattform eingestellten Inhalten ein. Sie sind dafür
                verantwortlich, dass Rechte Dritter dieser Nutzungseinräumung
                nicht entgegenstehen.
              </Paragraph>
            </li>

            <li>
              <Title>Haftungsausschluss</Title>
              <Paragraph>
                5.1 Jobz.Group haftet unbegrenzt für Schäden, die von Jobz.Group
                vorsätzlich oder grob fahrlässig verursacht wurden. In Fällen
                leichter Fahrlässigkeit haftet Jobz.Group bei Verletzung einer
                wesentlichen Vertragspflicht. Eine wesentliche Vertragspflicht,
                sogenannte Kardinalpflicht im Sinne der Rechtsprechung, ist eine
                Pflicht, deren Erfüllung die Durchführung des Vertrages erst
                ermöglicht und auf deren Erfüllung Sie sich deswegen regelmäßig
                verlassen dürfen.
              </Paragraph>
              <Paragraph hasM>
                5.2 Eine bestehende Haftung wegen leicht fahrlässiger Verletzung
                einer wesentlichen Vertragspflicht wird auf den vertragstypisch
                vorhersehbaren Schaden begrenzt. Im Übrigen ist die Haftung für
                leichte Fahrlässigkeit ausgeschlossen.
              </Paragraph>
              <Paragraph hasM>
                5.3 Eine Haftung nach dem Produkthaftungsgesetz oder aus der
                Verletzung von Leben, Körper und Gesundheit sowie nach §§ 44,
                44a TKG und bei Übernahme einer Garantie bleibt von den
                vorstehenden Haftungsbeschränkungen unberührt. Eine Änderung der
                Beweislast zum Nachteil des Nutzers ist mit den vorstehenden
                Regelungen nicht verbunden.
              </Paragraph>
              <Paragraph hasM>
                5.4 Jobz.Group übernimmt keine Haftung für unbefugt verschafften
                Zugang zu persönlichen Daten angemeldeter Kandidaten durch
                Dritte (beispielsweise durch einen unbefugten Zugriff von
                Hackern auf die Datenbank).
              </Paragraph>
              <Paragraph hasM>
                5.5 Die Haftungsbeschränkungen gelten zugunsten der Mitarbeiter,
                Beauftragten und Erfüllungsgehilfen von Jobz.Group entsprechend.
              </Paragraph>
            </li>
            <li>
              <Title>Widerrufsrecht des Vertrages</Title>
              <Paragraph>
                6.1 Widerrufsrecht
                <br />
                Sie können den Vertrag mit Jobz.Group binnen vierzehn (14) Tagen
                ohne Angabe von Gründen widerrufen. Die Widerrufsfrist beträgt
                vierzehn (14) Tage ab dem Tag des Vertragsschlusses. Um Ihr
                Widerrufsrecht auszuüben, müssen Sie Jobz.Group mittels einer
                eindeutigen Erklärung (z.B. ein mit der Post versandter ,
                Telefax oder E-Mail) über Ihren Entschluss, diesen Vertrag zu
                widerrufen, informieren. Zur Wahrung der Widerrufsfrist genügt
                es, dass Sie die Mitteilung über die Ausübung des
                Widerrufsrechts vor Ablauf der Widerrufsfrist absenden. Der
                Widerruf kann per Post an die{" "}
                <Link
                  link="https://app.legalhead.de/impressum"
                  linkText="Jobz.Group - Jobz GmbH"
                />{" "}
                oder per E-Mail an info@Jobz.Group gesendet werden.
              </Paragraph>
              <Paragraph>
                6.2 Widerrufsfolgen
                <br />
                Wenn Sie diesen Vertrag widerrufen, hat Jobz.Group Ihnen alle
                Zahlungen, die wir von Ihnen erhalten haben, einschließlich der
                Lieferkosten (mit Ausnahme der zusätzlichen Kosten, die sich
                daraus ergeben, dass Sie eine andere Art der Lieferung als die
                von uns angebotene, günstigste Standardlieferung gewählt haben),
                unverzüglich und spätestens binnen vierzehn (14) Tagen ab dem
                Tag zurückzuzahlen, an dem die Mitteilung über Ihren Widerruf
                dieses Vertrags bei Jobz.Group eingegangen ist. Für diese
                Rückzahlung verwendet Jobz.Group dasselbe Zahlungsmittel, das
                Sie bei der ursprünglichen Transaktion eingesetzt haben, es sei
                denn, mit Ihnen wurde ausdrücklich etwas anderes vereinbart; in
                keinem Fall werden Ihnen wegen dieser Rückzahlung Entgelte
                berechnet.
              </Paragraph>
            </li>

            <li>
              <Title>Kündigung der Nutzung</Title>
              <Paragraph>
                7.1 Unabhängig vom Widerrufsrecht sind beide Parteien
                berechtigt, den Vertrag jederzeit ohne Angabe von Gründen in
                Textform zu kündigen. Die Kündigung kann etwa per Post an die{" "}
                <Link
                  link="https://app.legalhead.de/impressum"
                  linkText="Jobz.Group GmbH"
                />{" "}
                oder per E-Mail an info@Jobz.Group gesendet werden. Zudem stellt
                Jobz.Group auf der Plattform einen Kündigungsbutton bereit. Zum
                Schutz Ihrer Daten vor unbefugten Dritten behält Jobz.Group es
                sich vor, eine Identitätsfeststellung vorzunehmen, z. B. durch
                Abfrage des Benutzernamens und Ihrer bei Jobz.Group
                registrierten E-Mail-Adresse.
              </Paragraph>
              <Paragraph hasM>
                7.2 Wird Ihr Vertrag gekündigt, gleich ob von Ihnen oder von
                Jobz.Group, wird Jobz.Group Ihre personenbezogenen Bewerberdaten
                (u.a. Name, E-Mail, Telefonnummer, Nachrichten, hochgeladene
                Dateien) löschen, soweit Jobz.Group nicht gesetzlich zur
                Aufbewahrung verpflichtet ist oder Jobz.Group vertragliche
                Ansprüche gegenüber den Arbeitgebern geltend machen kann. Im
                letzteren Fall behält Jobz.Group es sich vor, Ihre erhobenen und
                erzeugten Daten solange festzuhalten, bis Jobz.Group diese
                Ansprüche erfolgreich geltend gemacht hat oder der Zeitraum der
                möglichen Geltendmachung verstrichen ist.
              </Paragraph>
            </li>

            <li>
              <Title>Änderung dieser Allgemeinen Geschäftsbedingungen</Title>
              <Paragraph>
                Jobz.Group behält sich das Recht vor, diese Allgemeinen
                Geschäftsbedingungen jederzeit und ohne Nennung von Gründen zu
                ändern. Die geänderten Allgemeinen Geschäftsbedingungen werden
                Ihnen spätestens zwei Wochen vor ihrem Inkrafttreten in Textform
                mitgeteilt. Die geänderten Allgemeinen Geschäftsbedingungen
                gelten als akzeptiert, wenn Sie diesen nicht innerhalb von zwei
                Wochen nach Erhalt der Mitteilung widersprechen. Bei der
                Mitteilung der geänderten Allgemeinen Geschäftsbedingungen wird
                Jobz.Group auf die Frist und die Folge der Nichterhebung eines
                Widerspruchs hinweisen.
              </Paragraph>
            </li>

            <li>
              <Title>Sonstiges</Title>
              <Paragraph>
                9.1 Es gilt das Recht der Bundesrepublik Deutschland unter
                Ausschluss des UN-Kaufrechts.
              </Paragraph>
              <Paragraph hasM>
                9.2 Ausschließlicher Gerichtsstand für sämtliche Streitigkeiten
                aus oder im Zusammenhang mit diesem Vertrag ist Düsseldorf,
                vorausgesetzt Sie sind Kaufmann oder haben keinen allgemeinen
                Gerichtsstand in Deutschland oder in einem anderen
                EU-Mitgliedsstaat, haben Ihren festen Wohnsitz nach
                Wirksamwerden dieser Allgemeinen Geschäftsbedingungen ins
                Ausland verlegt oder Ihr Wohnsitz oder gewöhnlicher
                Aufenthaltsort ist im Zeitpunkt der Klageerhebung nicht bekannt.
              </Paragraph>
              <Paragraph hasM>
                9.3 Sollten einzelne Bestimmungen dieser Allgemeinen
                Geschäftsbedingungen unwirksam sein oder werden und / oder den
                gesetzlichen Regelungen widersprechen, so wird hierdurch die
                Wirksamkeit der Allgemeinen Geschäftsbedingungen im Übrigen
                nicht berührt.
              </Paragraph>
            </li>
          </ol>
        </div>
        <p className="mt-8">Stand: 1. Januar 2022</p>
      </div>
    </div>
  );
};

export default TermsAndConditions;
