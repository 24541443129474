import { useEffect, useState } from "react";
import FormInput from "components/form-input";
import { Form, Formik } from "formik";
import { Button, Divider, Select, Stepper, Group } from "@mantine/core";
import { FaBuilding } from "react-icons/fa";
import {
  FiBriefcase,
  FiCheck,
  FiGlobe,
  FiKey,
  FiMail,
  FiMapPin,
  FiPhone,
  FiSliders,
  FiUser,
  FiX,
} from "react-icons/fi";
import { Link } from "react-router-dom";
import ImgMail from "assets/imgs/png/email.png";
import api from "utils/api";
import { showNotification } from "@mantine/notifications";
import { useSearchCity } from "hooks/search-city";
import { useDebouncedValue } from "@mantine/hooks";

interface FormValues {
  companyName: string;
  firstName: string;
  lastName: string;
  location: string;
  email: string;
  phoneNumber: string;
  website: string;
  companySize: string;
  companyIndustry: string;
  password: string;
  verifyPassword: string;
}

const companySizes = [
  { value: "1-10", label: "1-10" },
  { value: "11-50", label: "11-50" },
  { value: "51-100", label: "51-100" },
  { value: "101-500", label: "101-500" },
  { value: "501-1000", label: "501-1000" },
  { value: "1001-5000", label: "1001-5000" },
  { value: "5001-10000", label: "5001-10000" },
  { value: ">10000", label: ">10000" },
];

const SignupForm: React.FC = () => {
  const [active, setActive] = useState(0);
  const [industryOptions, setIndustryOptions] = useState([]);
  const nextStep = () =>
    setActive((current) => (current < 3 ? current + 1 : current));
  const prevStep = () =>
    setActive((current) => (current > 0 ? current - 1 : current));
  const [location, setLocation] = useState("");
  const [locationDebounced] = useDebouncedValue(location, 200);
  const [locationOptions] = useSearchCity(locationDebounced);

  const fetchCompanyIndustries = async () => {
    const { data } = await api.get("/lead/categories");
    setIndustryOptions(data?.categories || []);
  };

  const onValidate = (values: FormValues) => {
    const errors: Record<string, string | number> = {};
    console.log({ values });

    if (active === 0) {
      if (!values.email) {
        errors.email = "Eingabe ist erforderlich";
      } else if (
        !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
      ) {
        errors.email = "Ungültige Email Adresse";
      }
      if (!values.password) errors.password = "Eingabe ist erforderlich";
      if (!values.verifyPassword)
        errors.verifyPassword = "Eingabe ist erforderlich";
      if (values.verifyPassword && values.verifyPassword !== values.password)
        errors.verifyPassword = "Passwort stimmt nicht überein";
    } else if (active === 1) {
      if (!values.companyName) errors.companyName = "Eingabe ist erforderlich";
      if (!values.firstName) errors.firstName = "Eingabe ist erforderlich";
      if (!values.lastName) errors.lastName = "Eingabe ist erforderlich";
      if (!values.location) errors.location = "Eingabe ist erforderlich";
      if (!values.phoneNumber) errors.phoneNumber = "Eingabe ist erforderlich";
      if (!values.companyIndustry)
        errors.companyIndustry = "Eingabe ist erforderlich";
    }

    return errors;
  };

  const onSubmit = async (values: FormValues) => {
    if (active === 0) return nextStep();
    if (active === 1) {
      try {
        await api.post("company/signup", values);
        showNotification({
          color: "green",
          icon: <FiCheck />,
          message: "Konto erfolgreich angelegt",
        });
        return nextStep();
      } catch (err: any) {
        showNotification({
          title: "Fehler beim Erstellen des Kontos",
          color: "red",
          icon: <FiX />,
          message: err.response.data.message,
        });
      }
    }
  };

  useEffect(() => {
    fetchCompanyIndustries();
  }, []);

  return (
    <div className="container max-w-xl bg-white md:mx-auto m-4 shadow rounded-lg p-4">
      <Formik<FormValues>
        initialValues={{
          companyName: "",
          firstName: "",
          lastName: "",
          location: "",
          email: "",
          phoneNumber: "",
          website: "",
          companySize: "",
          companyIndustry: "",
          password: "",
          verifyPassword: "",
        }}
        validate={onValidate}
        onSubmit={onSubmit}
        validateOnBlur={false}
        validateOnChange={false}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          isSubmitting,
          setFieldValue,
        }) => (
          <Form>
            <h1 className="font-heading text-3xl text-primary">
              Wir verbinden Jobsuchende mit Ihrem Unternehmen
            </h1>
            <h2 className="font-sans font-semibold mt-3 text-sm text-gray-600">
              Jetzt registrieren und sofort qualifizierte Bewerber:innen
              kennenlernen
            </h2>
            <Divider my="sm" variant="dashed" />
            <Stepper active={active} onStepClick={setActive} breakpoint="sm">
              <Stepper.Step
                label="Erster Schritt"
                description="Benutzer-Details"
                allowStepSelect={active > 0 && active !== 2}
              >
                <Divider my="sm" variant="dotted" />
                <FormInput
                  placeholder="beispiel@email.com"
                  name="email"
                  value={values.email}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  invalid={errors.email && touched.email}
                  required
                  label="Email"
                  err={touched.email && errors.email}
                  icon={<FiMail />}
                />
                <Divider my="sm" variant="dashed" />
                <FormInput
                  placeholder="********"
                  name="password"
                  value={values.password}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  invalid={errors.password && touched.password}
                  required
                  label="Passwort"
                  type="password"
                  err={touched.password && errors.password}
                  icon={<FiKey />}
                />
                <Divider my="sm" variant="dashed" />
                <FormInput
                  placeholder="********"
                  name="verifyPassword"
                  value={values.verifyPassword}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  invalid={errors.verifyPassword && touched.verifyPassword}
                  required
                  type="password"
                  label="Passwort bestätigen"
                  err={touched.verifyPassword && errors.verifyPassword}
                  icon={<FiKey />}
                />
              </Stepper.Step>
              <Stepper.Step
                label="Zweiter Schritt"
                description="Unternehmens-Details"
                allowStepSelect={active > 1 && active !== 2}
              >
                <Divider my="sm" variant="dotted" />
                <FormInput
                  placeholder="Firmenname"
                  name="companyName"
                  value={values.companyName}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  invalid={errors.companyName && touched.companyName}
                  required
                  label="Ihr Unternehmen"
                  err={touched.companyName && errors.companyName}
                  icon={<FaBuilding />}
                />
                <Divider my="sm" variant="dashed" />
                <div className="flex flex-col md:flex-row">
                  <FormInput
                    placeholder="John"
                    name="firstName"
                    value={values.firstName}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    invalid={errors.firstName && touched.firstName}
                    required
                    label="Vorname"
                    err={touched.firstName && errors.firstName}
                    icon={<FiUser />}
                  />
                  <Divider
                    my="sm"
                    variant="dashed"
                    className="md:hidden block"
                  />
                  <div className="hidden md:block p-2" />
                  <FormInput
                    placeholder="Doe"
                    name="lastName"
                    value={values.lastName}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    invalid={errors.lastName && touched.lastName}
                    required
                    label="Nachname"
                    err={touched.lastName && errors.lastName}
                    icon={<FiUser />}
                  />
                </div>
                <Divider my="sm" variant="dashed" />
                <FormInput
                  placeholder="https://example.com"
                  name="website"
                  value={values.website}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  invalid={errors.website && touched.website}
                  label="Webseite"
                  err={touched.website && errors.website}
                  icon={<FiGlobe />}
                />
                <Divider my="sm" variant="dashed" />
                <div className="flex flex-col md:flex-row">
                  <Select
                    className="w-full"
                    placeholder="Berlin"
                    name="location"
                    required
                    variant="filled"
                    searchable
                    limit={5}
                    onSearchChange={setLocation}
                    label="Standort"
                    data={locationOptions.map((item) => ({
                      ...item,
                      value: item.label,
                      label: item.label,
                    }))}
                    icon={<FiMapPin />}
                    onChange={(data) => setFieldValue("location", data)}
                    onBlur={handleBlur}
                  />
                  <Divider my="sm" variant="dashed" />
                  <div className="hidden md:block p-2" />
                  <FormInput
                    placeholder="+49 123456789"
                    name="phoneNumber"
                    value={values.phoneNumber}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    invalid={errors.phoneNumber && touched.phoneNumber}
                    required
                    label="Telefonnummer"
                    err={touched.phoneNumber && errors.phoneNumber}
                    icon={<FiPhone />}
                  />
                </div>

                <Divider my="sm" variant="dashed" />
                <div className="flex flex-col md:flex-row">
                  <Select
                    error={errors.companySize && touched.companySize}
                    label="Unternehmensgröße"
                    variant="filled"
                    name="companySize"
                    placeholder="Select..."
                    data={companySizes}
                    icon={<FiSliders />}
                    value={values.companySize}
                    className="w-full"
                    onChange={(data) => setFieldValue("companySize", data)}
                    onBlur={handleBlur}
                  />
                  <Divider my="sm" variant="dashed" />
                  <div className="hidden md:block p-2" />
                  <Select
                    error={errors.companyIndustry && touched.companyIndustry}
                    label="Branche"
                    name="companyIndustry"
                    required
                    variant="filled"
                    className="w-full"
                    placeholder="Auswählen..."
                    data={industryOptions}
                    icon={<FiBriefcase />}
                    onChange={(data) => setFieldValue("companyIndustry", data)}
                    onBlur={handleBlur}
                  />
                </div>
              </Stepper.Step>
              <Stepper.Step
                label="Dritter Schritt"
                description="Email bestätigen"
                allowStepSelect={false}
              >
                <div>
                  <img className="mx-auto w-48" src={ImgMail} alt="mail" />
                  <h3 className="text-center max-w-md mx-auto font-semibold mt-2 mb-4 font-sans text-gray-800">
                    Um Ihr Konto zu verifizieren, klicken Sie bitte auf den
                    Bestätigungslink, den Sie soeben per Mail erhalten haben.
                  </h3>
                </div>
              </Stepper.Step>
              <Stepper.Completed>
                Completed, click back button to get to previous step
              </Stepper.Completed>
            </Stepper>
            {active !== 2 && (
              <>
                <Divider my="sm" variant="dashed" />
                <Group position="center">
                  {active && (
                    <Button variant="default" onClick={prevStep}>
                      Zurück
                    </Button>
                  )}
                  <Button
                    variant="filled"
                    className="bg-red-400 hover:bg-primary"
                    type="submit"
                    loading={isSubmitting}
                  >
                    Nächster Schritt
                  </Button>
                </Group>
              </>
            )}
            <Divider my="sm" variant="dashed" />
            <p className="text-center text-sm text-gray-800">
              Sie haben sich bereits registriert?{" "}
              <Link to="/login" className="text-red-400 font-semibold">
                Hier
              </Link>{" "}
              anmelden
            </p>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default SignupForm;
